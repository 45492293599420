* {
  box-sizing: border-box; 
}

body, pre {
  margin: 0;
  background-color: #f5f5f5d7;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

pre {
  font-size: inherit;
  white-space: pre-wrap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.caseCodeDiv {
  display: flex;
  flex-direction: row-reverse;
  flex-grow: 1;
  justify-content: space-between;
  /* gap: 20rem; */
}

/* || Font-size */
.small-font {
  font-size: 0.675rem;
}

.state-font {
  font-size: 0.8rem;
}

.mobileDropdownSize {
  font-size: 1.1rem !important;
  margin-left: 0.5rem !important;
  text-align: left !important;
}

.nav-link-size {
  font-size: 1.25em;
}

.field {
  font-size: 0.875rem;
}

/* || Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track-piece {
  background-color: #FFF;
}

::-webkit-scrollbar-thumb {
  background-color: #CBCBCB;
  outline: 2px solid #FFF;
  outline-offset: -2px;
  border: .1px solid #B7B7B7;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}

/* || Snippets */
.tag {
  border-radius: 2px;
  background-color: #53A3CD;
  color: white;
  padding: 0 0.175rem;
}

/* || Utils */
.hovereableIcon:hover {
  color: #0d6efd;
}

.link {
  text-decoration: none;
  color: #0d6efd
}

.link:hover {
  color: #0a58ca;
}

.menuOptions a {
  /* flex: 1 1 0px; */
  text-align: center;
}

.title {
  font-size: 1.6rem;
}

.font-size1-1 {
  font-size: 1.1rem !important;
}

.font-weight-450 {
  font-weight: 450 !important;
}

.z-index-1 {
  z-index: -1;
}

.width20 {
  width: 20% !important;
}

.width60 {
  width: 60% !important;
}

.width70 {
  width: 70% !important;
}

.width80 {
  width: 80% !important;
}

.width90 {
  width: 90% !important;
}

.width100 {
  width: 100% !important;
}


.text-center {
  text-align: center;
}

.text-centre {
  display: block;
  text-align: center;
}

.text-justify {
  display: block;
  text-align: justify;
}

.flex {
  display: flex;
}

.gap05 {
  gap: 0.5rem;
}

.flex-xevenly {
  display: flex;
  justify-content: space-evenly;
}

.flex-xbetween {
  display: flex;
  justify-content: space-between;
}

.flex-xaround {
  display: flex;
  justify-content: space-around;
}

.flex-xcenter {
  display: flex;
  justify-content: center;
}

.flex-xleft {
  display: flex;
  justify-content: left;
}

.flex-xright {
  display: flex;
  justify-content: right;
}

.flex-ycentre {
  display: flex;
  align-items: center;
}

.align-items {
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-align-baseline {
  align-items: baseline;
}

.block {
  display: block;
}

.none {
  display: none;
}

.inline-block {
  display: inline-block;
}

/* || Paddings */
.padding0 {
  padding: 0 !important;
}

.paddingt0 {
  padding-top: 0 !important;
}

.padding025 {
  padding: 0.25rem;
}

.paddingb0 {
  padding: 0 !important;
}

.padding05 {
  padding: 0.5rem;
}

.padding1 {
  padding: 1rem;
}

.paddinglr0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.paddinglr05 {
  padding: 0 0.5rem;
}

.paddingtb0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

/* || Margins */
.margin-auto {
  margin: 0 auto;
}

.margin0 {
  margin: 0 !important;
}

.margint025 {
  margin-top: 0.25rem !important;
}

.margint05 {
  margin-top: 0.5rem !important;
}

.margint1 {
  margin-top: 1rem !important;
}

.margintb1 {
  margin: 1rem 0;
}

.marginlauto {
  margin-left: auto !important;
}

.marginl1 {
  margin-left: 1rem !important;
}

.marginl05 {
  margin-left: 0.5rem !important;
}

.marginr025 {
  margin-right: 0.25rem;
}

.marginr05 {
  margin-right: 0.5rem;
}

.marginr1 {
  margin-right: 1rem !important;
}

.marginb025 {
  margin-bottom: 0.25rem;
}

.marginb05 {
  margin-bottom: 0.5rem !important;
}

.marginb1 {
  margin-bottom: 1rem !important;
}

.marginb0 {
  margin-bottom: 0 !important;
}

.marginh25 {
  margin: 0.25rem 0;
}

.marginlr0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.clickable {
  cursor: pointer;
}

.middle {
  vertical-align: middle;
}

/* || Tables */
.tableWrapper {
  display: block;
  overflow-x: scroll;
  margin: 1rem 0;
}

.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

/* .table {
  margin: 0 auto;
  width: 90% !important;
  display: table;
  border-bottom: 5px solid #644df3;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
} */

/* tbody tr {
  border-right: 1px solid #644df3;
} */

tbody {
  border-top: 0 !important;
  /* border-right: 2px solid #644df3; */
}

.blackAndWhite {
  background-color: black !important;
  border-top-left-radius: 10px;
  /* border-top-right-radius: 15px; */
  color: white;
}


th.numeric {
  background-color: #212529;
  color: white !important;
}

 /*tr td {
  border-right: 1px solid #737373; */
  /* border-style: dotted;
} */

.modal-header {
  background-color: black;
  color: white;
}

/* || Inputs */
input,
label,
textarea,
select,
td {
  font-size: 0.875rem;
}

input:disabled {
  color: #757575;
}

.pageTitle {
  font-size: 2.5rem;
  text-align: center;
  align-self: center;
}

.modal-content {
  border: 0 !important;
}

.modal-title {
  font-weight: normal !important;
}

div.form-group {
  margin: 0 1rem 1rem;
}

div.form-group.col-sm {
  margin: 0;
}

.error-validation {
  margin: 0 0 0 1rem;
}

/* || Buttons */
.button {
  background: rgb(91, 197, 241) !important;
  color: #fff;
  border: 0;
}

.buttonClose {
  background-color: black !important;
  /* background: rgb(54, 54, 54) !important; */
  color: #fff;
  border: 0;
}

.button-action {
  background-color: #59B2E0;
  outline: none;
  color: #fff;
  font-size: 14px;
  height: auto;
  font-weight: normal;
  padding: 15px 6px;
  text-transform: uppercase;
  border-color: #59B2E6;
}

.button-action:hover,
.button-action:focus {
  color: #fff;
  background-color: #53A3CD;
  border-color: #53A3CD;
  border-color: rgb(8, 8, 8);
}

.menuLink {
  text-decoration: none;
  color: white !important;
}

.submenuLink {
  text-decoration: none;
  color: black !important;
}

.first-column {
  width: 1%;
}

thead {
  border: 0 !important;
}

.responsiveLink {
  text-decoration: none;
  color: white;
}

.responsiveLink:hover {
  color: white;
}

.validation-error {
  font-size: 0.75rem;
}

/*  || Colors */
.black {
  color: black !important;
}

.white {
  color: white !important;
}

.red {
  color: red !important;
}

.light-red {
  background: rgb(241, 91, 116) !important;
}

th.green {
  background-color: rgb(104, 227, 118) !important;
}

div.green {
  background-color: rgb(104, 227, 118) !important;
  border-top-left-radius: 10px;
}

th.openedHeader, div.openedHeader {
  background-color: rgb(153 208 247) !important;
  height: auto;
}

th.assignedHeader, div.assignedHeader {
  background-color: rgb(136 233 139) !important;
  height: auto;
}

th.inProgressheader, div.inProgressheader {
  background-color: rgb(255 155 85) !important;
  height: auto;
}

th.closedHeader{
  background-color: rgb(177 178 180) !important;
}

div.closedHeader {
  background-color: rgb(177 178 180) !important;
  border-top-left-radius: 10px;
}

th.pauseHeader, div.pauseHeader {
  background-color: rgb(125 51 143) !important;
  height: auto;
}

th.importantHeader, div.importantHeader {
  background-color: rgb(255, 0, 0) !important;
  color: white !important;
  height: auto;
}

.grey {
  background-color: #8d8d8d;
  border-top-left-radius: 10px;
}

/* || Generics */
input[type="number"],
textarea {
  border: 1px solid #ddd;
}

.imageOrientation {
  width: 80%;
  height: auto;
}

/* || Media */
@media only screen and (orientation: landscape) {
  .imageOrientation {
    height: 90%;
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .flex-xevenly {
      gap: 1rem;
  }
}

@media only screen and (max-width: 750px) {
  .activationText {
      width: 80% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .faqAttach {
      width: 30% !important;
  }
}

@media only screen and (max-width: 800px) {
  .faqAttach {
      width: 50% !important;
  }
}

@media only screen and (max-width: 400px) {
  .faqAttach {
      width: 100% !important;
  }
}
/* || Other */
