.panelLogin>.panelHeading {
    color: #00415d;
    background-color: #fff;
    border-color: #fff;
    text-align: center;
}

.panelLogin>.panelHeading a {
    text-decoration: none;
    color: #919191;
    font-weight: bold;
    font-size: 15px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.panelLogin>.panelHeading a.active {
    color: #666;
    font-size: 18px;
}

.panelLogin>.panelHeading hr {
    margin-top: 10px;
    margin-bottom: 0px;
    clear: both;
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0));
}

.panelLogin input[type="text"],
.panelLogin input[type="tel"],
.panelLogin input[type="file"],
.panelLogin input[type="email"],
.panelLogin input[type="number"],
.panelLogin input[type="password"],
.panelLogin textarea {
    /* margin: 0 1.234375rem 1rem 1.234375rem !important; */
    min-width: 15rem;
    height: 45px;
    border: 1px solid #ddd;
    font-size: 16px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}

.panelLogin input:hover,
.panelLogin input:focus {
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: rgb(8, 8, 8);
}

.btnLogin {
    background-color: #59B2E0;
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: #59B2E6;
}

.btnLogin:hover,
.btnLogin:focus {
    color: #fff;
    background-color: #53A3CD;
    border-color: #53A3CD;
}

.forgotPassword {
    display: block;
    text-decoration: underline;
    color: #888;
    margin-bottom: 1rem;
}

.forgotPassword:hover,
.forgotPassword:focus {
    text-decoration: underline;
    color: #666;
}

.btnRegister {
    background-color: #1CB94E;
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: #1CB94A;
}

.btnRegister:hover,
.btnRegister:focus {
    color: #fff;
    background-color: #1CA347;
    border-color: #1CA347;
}

.block {
    display: block;
}

.none {
    display: none;
}

/* input:not(input[type=checkbox]) {
    width: 90%;
    margin: 0 auto;
    display: block;
} */
/* 
input[type=checkbox] {
    margin-right: 0.5rem;
} */

/* input[type=file] {
    height: 45px;
} */

textarea {
    width: 90%;
    display: block;
    margin: 0 auto;
    min-height: 3rem;
}

.email {
    width: 20%;
    display: block;
    margin: 0 auto;
}

.resend {
    margin-top: 1rem;
}

.activate {
    margin: 1rem;
}

label {
    display: inline-block !important;
}

/* input,
textarea {
    margin-bottom: 1rem !important;
} */

.minh100 {
    height: 100vh;
}

.styBtnLogin{
    background-color: "#000" !important;
    width: 50px !important;
    color:"#000";
    border:0;
    font-weight: bold;
}
.styBtnLogin:hover{
    background:"#028ECC";
}