.subMenu {
    border-radius: 6px;
    /* width: 125px; - se quito para que se adapte al contenido*/
    transform: translateY(-30%);
    position: absolute;
    background-color: rgb(214, 214, 214);
    visibility: hidden;
    opacity: 0;
    overflow-y: hidden;
    transition: transform 0.5s, visibility 0.5s, opacity 0.5s;
}

.subItem {
    padding: 5px;
}

.subItem:hover {
    background-color: #e3e3e3;
    transition: background-color 0.5s;
}

.dropdown:hover>div,
div.sub-menu:hover {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s, visibility 0.5s, opacity 0.5s;
}