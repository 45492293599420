.inlineBlock {
    display: inline-block;
}

.noMargin {
    margin: 0;
}

.line {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.iconHeight {
    height: 24px !important;
}

.optionFontSize {
    font-size: 0.875rem;
}

.warning {
    font-size: 0.675rem;
}

.img {
    width: 50px;
    margin: 0 0.5rem 0.25rem 0;
}