.mobileDropdown {
    height: 0;
    background-color: white;
    border-radius: 3px;
    visibility: hidden;
    opacity: 0;
    transform: translateY(-30%);
    margin: 0 auto;
}

.activeMobileDropdown {
    height: auto;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    /* transition: transform 0.5s, visibility 0.5s, opacity 0.5s, height 0.5s; */
}

.mobileDropdownOption {
    background-color: #fff;
    border-radius: 6px;
}

.mobileDropdownOption:hover,
.mobileDropdownOption:active {
    background-color: #e3e3e3 !important;
    transition: background-color 0.5s;
}