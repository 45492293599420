.separator {
    margin: 0.15rem 0 0.5rem 0;
}

.customGroup {
    display: inline-block;
    width: auto;
    margin: 0 0 0 0.25rem !important;
}

.customInput {
    display: inline-block !important;
    padding: 0 !important;
    width: auto !important;
    margin-left: 0.15rem;
}