:root {
  --sidebarBackgroundColor: #737373;
}

.App {
  text-align: center;
  background-color: #f5f5f5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

img.small {
  height: 20px;
  margin: -5 5px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hide {
  margin-left: -300px;
  transition: 0.4s;
}

a.navbar-brand {
  margin: 0;
}

.navbar {
  z-index: 1;
  width: 250px;
  height: 100vh;
  position: fixed;
  background-color: var(--sidebarBackgroundColor);
}

.nav-link {
  font-size: 1.25em;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #ffffff26;
}

/* || Nav */
nav {
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
  margin-bottom: 1rem;
}

.menuOptions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.menuOptions_admin {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.bars {
  display: none;
}

.bars_admin {
  display: none;
}

.outOfMenu {
  margin-left: 250px;
  width: 100vh;
  height: 100vh;
  position: fixed;
  background-color: rgba(53, 59, 60, 0.5);
  z-index: 1;
}

.dot {
  position: fixed;
  height: 50px;
  width: 50px;
  background-color: var(--sidebarBackgroundColor);
  border-radius: 50%;
  margin-left: 270px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 100ms cubic-bezier(0, 0, .38, .9) 400ms;
  transform: scale(0);
  z-index: 2;
}

.dotScale {
  transform: scale(1);
}


.dot p {
  margin: 0;
  color: white;
  font-size: 25px;
}

.sub-menu {
  border-radius: 6px;
  width: 125px;
  transform: translateY(-30%);
  position: absolute;
  background-color: rgb(214, 214, 214);
  visibility: hidden;
  opacity: 0;
  overflow-y: hidden;
  transition: transform 0.5s, visibility 0.5s, opacity 0.5s;
}

.sub-item {
  padding: 5px;
}

.sub-item:hover {
  background-color: #e3e3e3;
  transition: background-color 0.5s;
}

.dropdown:hover >div, div.sub-menu:hover {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.5s, visibility 0.5s, opacity 0.5s;
}

.imgLogin{
  width: 1600px;
  height: auto;
  position: fixed;
  top: -10px;
  left: -850px;
  z-index: -1;
}

.contentHeadTitle{
  height: 150px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 55px;
  border-bottom: 5px;
  border-bottom-style: solid;
  border-bottom-color: #d3d3d3;
  justify-content: center;
}

.imgHeadTitle{
  width: 1600px;
  height: auto;
  position: absolute;
  top: -12cm;
  left: -50em;
  z-index: -1;
}

@media only screen and (max-width: 900px){
  .bars {
    display: block;
  }

  .menuOptions {
    display: none;
  }

  .logoWrapper {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .imgLogin{
    width: 0px;
  }
  
}

@media only screen and (max-width: 1200px) {
  .imgHeadTitle{
    width: 1400px;
    height: auto;
    position: absolute;
    top: -11cm;
    left: -50em;
    z-index: -1;
  }
  .bars_admin {
    display: block;
  }

  .menuOptions_admin {
    display: none;
  }

  .logoWrapper_admin {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
}

svg {
  margin: 0 !important;
}

svg.bi {
  /* height: 23px; */
}

h4 {
  font-weight: normal;
}
/* 
input[type=submit]:disabled {
  background-color: #585858;
} */

/* //ANCHOR EDITOR */

/* .rdw-storybook-wrapper {} */

.rdw-storybook-editor {
  border       : 1px solid #ddd;
  padding      : 5px;
  border-radius: 2px;
  height       : 400px;
}

/* .rdw-storybook-toolbar {} */

.rdw-storybook-textarea {
  margin-top: 20px;
  resize    : none;
  width     : 100%;
  border    : 1px solid #ddd;
  height    : 200px;
}