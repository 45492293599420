.faqQuestion {
    color: #3b6caa;
}

.faqQuestion:hover {
    text-decoration: underline;
}

.faqIcon {
    font-weight: bolder;
}

.faqImage {
    width: 20%;
    cursor: pointer;
}

@media only screen and (max-width: 1100px) {
    .faqImage {
        width: 30%;
    }
}

@media only screen and (max-width: 800px) {
    .faqImage {
        width: 50%;
    }
}